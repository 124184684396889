$regular: (
  '10': 10px 400 18px,
  '12': 12px 400 20px,
  '14': 14px 400 22px,
  '16': 16px 400 24px,
  '18': 18px 400 26px,
  '20': 20px 400 28px,
  '24': 24px 400 32px,
  '30': 30px 400 38px,
  '32': 32px 400 40px,
  '38': 38px 400 46px,
  '46': 46px 400 54px,
  '56': 56px 400 64px
);

$normal: (
  '10': 10px 500 18px,
  '12': 12px 500 20px,
  '14': 14px 500 22px,
  '16': 16px 500 24px,
  '18': 18px 500 26px,
  '20': 20px 500 28px,
  '24': 24px 500 32px,
  '30': 30px 500 38px,
  '38': 38px 500 46px,
  '46': 46px 500 54px,
  '56': 56px 500 64px
);

$semibold: (
  '10': 10px 600 18px,
  '12': 12px 600 20px,
  '14': 14px 600 22px,
  '16': 16px 600 24px,
  '18': 18px 600 26px,
  '20': 20px 600 28px,
  '24': 24px 600 32px,
  '30': 30px 600 38px,
  '38': 38px 600 46px,
  '46': 46px 600 54px,
  '56': 56px 600 64px
);

$bold: (
  '10': 10px 700 16px,
  '12': 12px 700 20px,
  '14': 14px 700 22px,
  '16': 16px 700 24px,
  '18': 18px 700 26px,
  '20': 20px 700 28px,
  '24': 24px 700 32px,
  '30': 30px 700 38px,
  '32': 32px 700 42px,
  '38': 38px 700 46px,
  '46': 46px 700 54px,
  '56': 56px 700 64px
);

$extrabold: (
  '10': 10px 800 16px,
  '12': 12px 800 20px,
  '14': 14px 800 22px,
  '16': 16px 800 24px,
  '18': 18px 800 26px,
  '20': 20px 800 28px,
  '24': 24px 800 32px,
  '30': 30px 800 38px,
  '32': 32px 800 42px,
  '38': 38px 800 46px,
  '46': 46px 800 54px,
  '56': 56px 800 64px
);

$font-styles: (
  'regular': $regular,
  'semibold': $semibold,
  'bold': $bold,
  'normal': $normal,
  'extrabold': $extrabold
);

@each $name, $value in $font-styles {
  @each $key, $props in $value {
    #{'.' + $name + '-' + $key} {
      font-size: nth($props, 1);
      font-weight: nth($props, 2);
      line-height: nth($props, 3);
    }
  }
}

:root {
  $font-primary: 'M PLUS Rounded 1c', 'Open Sans', sans-serif;
  // generate css vars
  @each $name, $value in $font-styles {
    @each $key, $props in $value {
      --font-#{ $name + '-' + $key}: #{nth($props, 2) nth($props, 1)}/#{nth($props, 3)} #{$font-primary};
    }
  }
}
