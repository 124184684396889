.pagination {
  display: flex;
  align-items: center;
  align-items: flex-end;
  flex: 1;
  justify-content: center;
  list-style: none;
  gap: 8px;
  padding: 0;
  margin: 0 auto 10px;
  @media (max-width: 900px) {
    font-size: 12px;
  }
}

.pagination li {
  border: 1px solid var(--dark-green);
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  & a {
    display: inline-block;
    padding: 8px 12px;
    color: var(--description-solid);
  }
}
.pagination.pagination-admin li {
  border: 1px solid var(--primary-color);
}

.pagination .selected {
  background: var(--text-green);
  color: white;
  font-weight: bold;
  & a {
    color: white;
  }
}
.pagination.pagination-admin .selected {
  background: var(--gradient-purple);
}

.pagination .previous,
.pagination .next {
  border-radius: 4px;
  cursor: pointer;
}

.pagination .previous.disabled,
.pagination .next.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
