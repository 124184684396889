@import 'colors';
@import 'fontStyles';
@import 'helperClasses';
@import 'paginate';

body {
  margin: 0;
  font-family: 'Rounded Mplus 1c', 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #231b45;
  height: 100vh;
  overflow-y: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --scrollbar-width: 10px;
  --scrollbar-bg: #f0f0f0;
  --scrollbar-thumb-bg: #b0b0b0;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb-bg) var(--scrollbar-bg);
}

*::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

*::-webkit-scrollbar-track {
  background: var(--scrollbar-bg);
  border-radius: 0;
}

*::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-bg);
  border-radius: 10px;
}

@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-bg) var(--scrollbar-bg);
  }
}
